import ChildCareIcon from '@mui/icons-material/ChildCare'
import React from 'react'
import AnimateIn from './AnimateIn'
import Flex from './Flex'
import Spacer from './Spacer'

export default function LoadingScreen() {
  return (
    <AnimateIn className="fill">
      <Flex direction="column" height="100%">
        <div className="text-center">
          <Spacer>
            <ChildCareIcon className="spin" fontSize="large" />
          </Spacer>
          Loading...
        </div>
      </Flex>
    </AnimateIn>
  )
}
