

import React from 'react';
import { Route } from 'wouter';
import HomePage from '~/pages/HomePage';
import BabyPage from '~/pages/BabyPage';
import Providers from '~/Providers';
	
export default function App() {
  return (
    <Providers>
      <Route path="/" component={HomePage} />
      <Route path="/babies/:babyId/day/:day">
          {(params) => <BabyPage babyId={params.babyId} day={params.day} />}
      </Route>
    </Providers>
  )
}
