import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Paper from '@mui/material/Paper'
import { TimePicker } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'
import React from 'react'
import Flex from '~/components/Flex'
import Pad from '~/components/Pad'
import Spacer from '~/components/Spacer'
import * as State from '~/state'

interface NewChangingFormProps {
  onCancel: () => void;
}

export default function NewChangingForm({ onCancel }: NewChangingFormProps) {
  const createEvent = State.useState(State.select.createEvent)

  const [startTime, setStartTime] = React.useState<Dayjs | null>(null)
  const [pee, setPee] = React.useState(false)
  const [poo, setPoo] = React.useState(false)

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    createEvent({
      pee,
      poo,
      startTime: (startTime as Dayjs).toISOString(),
      type: 'changing',
    })
    onCancel()
  }

  return (
    <Paper sx={{ padding: '1em' }}>
      <form className="text-center" onSubmit={handleSubmit}>
        <h3><BabyChangingStationIcon sx={{ position: 'relative', top: '4px' }} /> Track New Changing</h3>
        <Spacer>
          <Pad>
            <Spacer>
              <p>Time</p>
            </Spacer>
            <Spacer>
              <TimePicker onChange={setStartTime} value={startTime} />
            </Spacer>
          </Pad>
          <Flex>
            <Pad>
              <Checkbox id="pee" checked={pee} onChange={() => setPee(!pee)} />
              <label htmlFor="pee">Pee</label>
            </Pad>
            <Pad>
              <Checkbox id="poo" checked={poo} onChange={() => setPoo(!poo)} />
              <label htmlFor="poo">Poo</label>
            </Pad>
          </Flex>
        </Spacer>
        <Flex>
          <Pad>
            <Spacer>
              <Button variant="outlined" onClick={onCancel}>Cancel</Button>
            </Spacer>
          </Pad>
          <Pad>
            <Spacer>
              <Button disabled={!startTime} type="submit" variant="contained">Submit</Button>
            </Spacer>
          </Pad>
        </Flex>
      </form>
    </Paper>
  )
}
