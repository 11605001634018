import AnimateIn from '~/components/AnimateIn'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import LoadingIcon from '@mui/icons-material/Autorenew'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper'
import dayjs from 'dayjs'
import React from 'react'
import Flex from '~/components/Flex'
import Pad from '~/components/Pad'
import * as State from '~/state'
import EditChangingForm from './EditChangingForm'
import EditFeedingForm from './EditFeedingForm'

function FeedingEventChip(props: State.FeedingEvent) {
  const deleteEvent = State.useState(State.select.deleteEvent)
  
  const [loading, setLoading] = React.useState(false)
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  
  const closeModal = () => setModalIsOpen(false)
  const handleClickEdit = (e: React.MouseEvent) => {
    e.preventDefault()
    setModalIsOpen(true)
  }
  const handleClickDelete = (e: React.MouseEvent) => {
    e.preventDefault()
    setLoading(true)
    deleteEvent(props._id)
  }

  return (
    <Paper sx={{ backgroundColor: '#eee' }}>
      <Pad>     
        <Flex justify="space-between">
          <Flex justify="space-evenly">
            <Pad padding={0.25}>🍼</Pad>
            <p><small>Feeding</small></p>
          </Flex>
          <Flex justify="space-evenly">
            <p><small>{dayjs(props.startTime).format('h:mm A')}</small></p>
            {props.endTime ? (
              <>
                <p style={{ margin: '0 2px' }}><small> to </small></p>
                <p><small>{dayjs(props.endTime).format('h:mm A')}</small></p>
              </>
            ) : null}
          </Flex>
          <Flex justify="space-evenly">
            <IconButton disabled={loading} size="small" onClick={handleClickEdit}>
              <EditIcon />
            </IconButton>
            <IconButton disabled={loading} size="small" onClick={handleClickDelete}>
              {loading ? <LoadingIcon className="spin" /> : <DeleteIcon />}
            </IconButton>
          </Flex>
        </Flex>
      </Pad>
      <Modal disableEnforceFocus open={modalIsOpen} onClose={closeModal}>
        <Flex fill>
          <EditFeedingForm data={props} onCancel={closeModal} />
        </Flex>
      </Modal>
    </Paper>
  )
}

function ChangingEventChip(props: State.ChangingEvent) {
  const deleteEvent = State.useState(State.select.deleteEvent)
  
  const [loading, setLoading] = React.useState(false)
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  
  const closeModal = () => setModalIsOpen(false)
  const handleClickEdit = (e: React.MouseEvent) => {
    e.preventDefault()
    setModalIsOpen(true)
  }
  const handleClickDelete = (e: React.MouseEvent) => {
    e.preventDefault()
    setLoading(true)
    deleteEvent(props._id)
  }

  return (
    <Paper sx={{ backgroundColor: '#eee' }}>
      <Pad>
        <Flex justify="space-between">
          <Flex justify="space-evenly">
            {props.poo && '💩'}
            {props.pee && '💧'}
            <p><small>Changing</small></p>
          </Flex>
          <Flex justify="space-evenly">
            <p><small>{dayjs(props.startTime).format('h:mm A')}</small></p>
          </Flex>
          <Flex justify="space-evenly">
            <IconButton disabled={loading} size="small" onClick={handleClickEdit}>
              <EditIcon />
            </IconButton>
            <IconButton size="small" onClick={handleClickDelete}>
              {loading ? <LoadingIcon className="spin" /> : <DeleteIcon />}
            </IconButton>
          </Flex>
        </Flex>
      </Pad>
      <Modal disableEnforceFocus open={modalIsOpen} onClose={closeModal}>
        <Flex fill>
          <EditChangingForm data={props} onCancel={closeModal} />
        </Flex>
      </Modal>
    </Paper>
  )
}

export default function EventChip(props: State.ChangingEvent | State.FeedingEvent) {
  return props.type === 'changing' ? (
    <AnimateIn key={props._id}>
      <ChangingEventChip {...props} />
    </AnimateIn>
  ) : props.type === 'feeding' ? (
    <AnimateIn key={props._id}>
      <FeedingEventChip {...props} />
    </AnimateIn>
  ) : null
}
