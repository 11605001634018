
const API_ROOT = process.env.REACT_APP_API_ROOT || '/.netlify/functions'
const API_KEY = process.env.REACT_APP_API_KEY || ''

async function _fetch<T>(method: string, path: string, body?: any): Promise<T> {
  const options = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      'X-API-Key': API_KEY,
    },
  }
  if (body) {
    (options as any).body = JSON.stringify(body)
  }
  const response = await fetch(`${API_ROOT}${path}`, options)
  const responseJson = await response.json()
  return responseJson
}

export function get<T>(path: string): Promise<T> {
  return _fetch<T>('get', path)
}

export function post<T>(path: string, body?: any): Promise<T> {
  return _fetch<T>('post', path, body)
}
