import CloseIcon from '@mui/icons-material/Close'
import MuiSnackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import * as React from 'react'
import * as State from '~/state'

export default function Snackbar() {
  const open = State.useState(State.select.snackbarOpen)
  const message = State.useState(State.select.snackbarMessage)
  const close = State.useState(State.select.closeSnackbar)

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    close()
  }

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )

  return (
    <MuiSnackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={3000}
      onClose={handleClose}
      message={message}
      action={action}
    />
  )
}
