import { Dayjs } from 'dayjs'
import { create } from 'zustand'
import * as api from '~/services/api'

export interface Event {
  _id: string;
  babyId: string;
  day: number;
  startTime: string;
  type: 'feeding' | 'changing';
}

export interface EventInput extends Omit<Event, '_id' | 'babyId' | 'day'> {}

export interface FeedingEvent extends Event {
  type: 'feeding';
  endTime: string | null;
  note?: string;
}

export interface FeedingEventInput extends Omit<FeedingEvent, '_id' | 'babyId' | 'day'> {}

export interface ChangingEvent extends Event {
  type: 'changing';
  poo: boolean;
  pee: boolean;
  note?: string;
}

export interface ChangingEventInput extends Omit<ChangingEvent, '_id' | 'babyId' | 'day'> {}

export interface Baby {
  _id: string;
  birthDate: string;
  birthTime: string;
}

export interface StoreState {
  baby: Baby | null;
  setBaby: (id: string) => void;
  createBaby: (birthDate: Dayjs, birthTime: Dayjs, redirect: (path: string) => void) => void;

  events: {
    [k: string]: ChangingEvent | FeedingEvent;
  };
  createEvent: (ei: ChangingEventInput | FeedingEventInput) => void;
  deleteEvent: (id: string) => void;
  updateEvent: (id: string, ei: Partial<ChangingEventInput | FeedingEventInput>) => void;

  day: number;
  setDay: (day: number) => void;

  view: 'events' | 'stats';
  setView: (v: 'events' | 'stats') => void;

  snackbarOpen: boolean;
  snackbarMessage?: string;
  openSnackbar: (message: string) => void;
  closeSnackbar: () => void;
}

export const useState = create<StoreState>((set, getState) => ({
  baby: null,
  setBaby: async (_id) => {
    const state = getState()

    if (!state.baby || _id !== state.baby?._id) {
      const { baby, events } = await api.post<{ baby: Baby, events: (ChangingEvent | FeedingEvent)[] }>('/readBaby', { _id })
      const eventsObj = events.reduce((acc, curr) => { acc[curr._id] = curr; return acc; }, {})
      set({ baby, events: eventsObj })
    }
  },
  createBaby: async (bd: Dayjs, bt: Dayjs, redirect: (path: string) => void) => {
    const birthDate = bd.toISOString()
    const birthTime = bt.toISOString()
    
    const res = await api.post<Baby>('/createBaby', {
      birthDate,
      birthTime,
    })
    
    set({
      baby: {
        _id: res._id,
        birthDate,
        birthTime,
      },
    })
    
    redirect(`/babies/${res._id}/day/0`)
  },

  events: {},
  createEvent: async (ei: ChangingEventInput | FeedingEventInput) => {
    const state = getState()

    const ev = {
      day: state.day,
      babyId: state.baby?._id as string,
      ...ei,
    }

    set({
      snackbarOpen: true,
      snackbarMessage: 'Saving event...',
    })

    const res = await api.post<ChangingEvent | FeedingEvent>('/createEvent', ev)
    
    set({
      events: {
        ...state.events,
        [res._id]: {
          _id: res._id,
          ...ev,
        },
      },
      snackbarMessage: 'Saved!',
    })
  },
  deleteEvent: async (_id: string) => {
    set({
      snackbarOpen: true,
      snackbarMessage: 'Deleting...',
    })
    const res = await api.post<{ _id: string }>('/deleteEvent', { _id })
    set((state) => {
      const { [res._id]: _, ..._events } = state.events
      return {
        events: _events,
        snackbarMessage: 'Deleted!',
      }
    })
  },
  updateEvent: async (_id: string, ei: Partial<ChangingEventInput | FeedingEventInput>) => {
    const state = getState()

    const originalEvent = state.events[_id]

    const ev = {
      _id,
      ...ei,
    }

    set({
      snackbarOpen: true,
      snackbarMessage: 'Saving event...',
    })

    const res = await api.post<ChangingEvent | FeedingEvent>('/updateEvent', ev)

    set({
      events: {
        ...state.events,
        [res._id]: {
          ...originalEvent,
          ...ei,
        },
      },
      snackbarMessage: 'Saved!',
    })
  },

  day: 0,
  setDay: (day) => set({ day }),

  view: 'events',
  setView: (view) => set({ view }),

  snackbarOpen: false,
  snackbarMessage: '',
  openSnackbar: (message: string) => {
    set({
      snackbarOpen: true,
      snackbarMessage: message,
    })
  },
  closeSnackbar: () => set({ snackbarOpen: false }),
}))

export const select = {
  baby: (state: StoreState) => state.baby,
  createBaby: (state: StoreState) => state.createBaby,
  setBaby: (state: StoreState) => state.setBaby,
  
  events: (state: StoreState) => state.events,
  createEvent: (state: StoreState) => state.createEvent,
  deleteEvent: (state: StoreState) => state.deleteEvent,
  updateEvent: (state: StoreState) => state.updateEvent,
  
  day: (state: StoreState) => state.day,
  setDay: (state: StoreState) => state.setDay,

  view: (state: StoreState) => state.view,
  setView: (state: StoreState) => state.setView,

  snackbarOpen: (state: StoreState) => state.snackbarOpen,
  snackbarMessage: (state: StoreState) => state.snackbarMessage,
  openSnackbar: (state: StoreState) => state.openSnackbar,
  closeSnackbar: (state: StoreState) => state.closeSnackbar,
}
