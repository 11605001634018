import AnimateIn from '~/components/AnimateIn'
import React from 'react'
import { Dayjs } from 'dayjs'
import { useLocation } from 'wouter'
import DatePickerCard from '~/components/DatePickerCard'
import Flex from '~/components/Flex'
import PageWidth from '~/components/PageWidth'
import TimePickerCard from '~/components/TimePickerCard'
import * as State from '~/state'

export default function HomePage() {
  const [birthDate, setBirthDate] = React.useState<Dayjs | null>(null)
  const [birthTime, setBirthTime] = React.useState<Dayjs | null>(null)
  const [timePickerIsShowing, setTimePickerIsShowing] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [, setLocation] = useLocation()

  const createBaby = State.useState(State.select.createBaby)

  return (
    <Flex direction="column" height="100%">
      <PageWidth style={{ flexGrow: 0 }}>
        <h1 className="text-center">Start tracking baby</h1>
      </PageWidth>
      <Flex direction="column" grow={1}>
        {!timePickerIsShowing && (
          <AnimateIn>
            <DatePickerCard
              buttonLabel="Next"
              label="Enter Baby's Birth Date:"
              onChange={setBirthDate}
              onClick={() => setTimePickerIsShowing(true)}
              value={birthDate}
            />
          </AnimateIn>
        )}
        {timePickerIsShowing && (
          <AnimateIn>
            <TimePickerCard
              buttonLabel="Begin"
              label="Enter Baby's Birth Time:"
              loading={loading}
              onChange={setBirthTime}
              onClick={() => {
                setLoading(true)
                createBaby(birthDate as Dayjs, birthTime as Dayjs, setLocation)
              }}
              value={birthTime}
            />
          </AnimateIn>
        )}
      </Flex>
    </Flex>
  )
}
