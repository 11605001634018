/**
 * AnimateIn
 *
 * show a component after it has mounted via css classes
 */
import React from 'react';

interface AnimateInProps {
  animClassName?: string;
  children: React.ReactNode;
  className?: string;
  inClassName?: string;
  delay?: number;
  outClassName?: string;
}

const AnimateIn = ({
  animClassName = 'fade',
  children,
  className = '',
  delay = 0,
  inClassName = 'fade--in',
  outClassName = 'fade--out',
}: AnimateInProps) => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const HAS_WINDOW = typeof window !== 'undefined';
    let timeoutId: NodeJS.Timeout;

    if (HAS_WINDOW) {
      timeoutId = setTimeout(() => setShow(true), delay);
    }
    
    return () => {
      if (HAS_WINDOW) {
        clearTimeout(timeoutId);
      }
    };
  }, [delay]);
  
  return (
    <div
      className={`${className} ${animClassName} ${show ? inClassName : outClassName}`}
    >
      {children}
    </div>
  );
};

export default AnimateIn;
