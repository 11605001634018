import React from 'react'
import AnimateIn from '~/components/AnimateIn'
import Spacer from '~/components/Spacer'
import * as State from '~/state'
import * as DateUtils from '~/utils/date'

function hrsToMs(hrs: number): number {
  return hrs * 3600000
}

function formatMs(ms: number): string {
  let seconds = ms / 1000
  const hours = Math.floor(seconds / 3600) // extract hours
  seconds = seconds % 3600 // seconds remaining after extracting hours
  const minutes = Math.floor(seconds / 60) // extract minutes
  const hoursStr = hours ? `${hours}h` : ''
  const minStr = minutes ? `${minutes}min` : ''
  return `${hoursStr}${minStr}`
}

function calcAvgFeedLength(events: (State.FeedingEvent | State.ChangingEvent)[]) {
  const feedLengths = (events as State.FeedingEvent[])
    .filter((ev) => ev.type === 'feeding' && ev.endTime)
    .map((ev) => {
      const startMs = DateUtils.getMsFromDateString(ev.startTime)
      const endMs = DateUtils.getMsFromDateString(ev.endTime)

      let diff = endMs - startMs

      /**
       * if diff is negative, time period has wrapped around to next day
       * invert calculation 24.00 - 23.58 + 00.02 = 0.04
       */
      if (diff < 0) {
        diff = (24 * 60 * 60 * 1000) - startMs + endMs
      }

      return diff
    })
  const feedLengthsSum = feedLengths.reduce((acc, curr) => acc + curr, 0)
  const avgFeedLength = Math.round(feedLengthsSum / feedLengths.length)
  const avgFeedLengthStr = !feedLengths.length ? 'n/a' : formatMs(avgFeedLength)
  return avgFeedLengthStr
}

function calcAvgFeedFreq(feedCount: number): string {
  if (!feedCount) {
    return 'n/a'
  }
  return formatMs(hrsToMs(24 / feedCount)) 
}

export default function Stats() {
  const events = State.useState(State.select.events)
  const day = State.useState(State.select.day)

  const eventsArr: (State.FeedingEvent | State.ChangingEvent)[] = Object.values(events)
  const filteredEvents = eventsArr.filter((ev) => ev.day === day)

  const peeCount = filteredEvents.filter((ev) => ev.type === 'changing' && ev.pee).length
  const pooCount = filteredEvents.filter((ev) => ev.type === 'changing' && ev.poo).length
  const feedCount = filteredEvents.filter((ev) => ev.type === 'feeding').length

  const avgFeedLength = calcAvgFeedLength(filteredEvents)

  const avgFeedFreq = calcAvgFeedFreq(feedCount)

  return (
    <AnimateIn>
      <div className="text-center">
        <Spacer>
          <p>💧 Total Pees: <strong>{peeCount}</strong></p>
          <p>💩 Total Poos: <strong>{pooCount}</strong></p>
          <p>🍼 Total Feeds: <strong>{feedCount}</strong></p>
          <p>Avg. Feed Length: <strong>{avgFeedLength}</strong></p>
          <p>Avg. Feed Freq.: <strong>{avgFeedFreq}</strong></p>
        </Spacer>
      </div>
    </AnimateIn>
  )
}
