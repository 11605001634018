import React from 'react';

interface PageWidthProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export default function PageWidth({ children, className = '', style = {} }: PageWidthProps) {
  return (
    <div
      className={className}
      style={{
        boxSizing: 'border-box',
        margin: '0 auto',
        maxWidth: '600px',
        padding: '0 20px',
        position: 'relative',
        width: '100%',
        ...style
      }}
    >
      {children}
    </div>
  )
}
