import React from 'react'

interface PadProps {
  children: React.ReactNode;
  padding?: number;
  style?: React.CSSProperties;
}

function Pad({
  children,
  padding = 0.5,
  style = {},
}: PadProps, ref: React.Ref<HTMLDivElement>) {
  return (
    <div
      ref={ref}
      style={{
        paddingLeft: `${padding}em`,
        paddingRight: `${padding}em`,
        ...style
      }}
    >
      {children}
    </div>
  )
}

export default React.forwardRef(Pad)
