import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import BarChartIcon from '@mui/icons-material/BarChart'
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'
import IconButton from '@mui/material/IconButton'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import dayjs from 'dayjs'
import React from 'react'
import { useLocation } from 'wouter'
import PageWidth from '~/components/PageWidth'
import Spacer from '~/components/Spacer'
import * as State from '~/state'

interface HeaderProps {
  babyId: string;
  birthDate: string;
  birthTime: string;
  day: number;
  style?: React.CSSProperties;
}

export default function Header({ babyId, birthDate, birthTime, day, style = {} }: HeaderProps) {
  const [, setLocation] = useLocation()

  const view = State.useState(State.select.view)
  const setView = State.useState(State.select.setView)

  const bd = dayjs(birthDate)
  const bt = dayjs(birthTime)
  const dayStart = bd.add(day, 'day').format('MMM D')
  const dayEnd = bd.add(day, 'day').add(1, 'day').format('MMM D')
  const dayTime = bt.format('h:mm A')

  return (
    <PageWidth className="text-center" style={{ borderBottom: '1px solid #aaa', ...style }}>
      {day > 0 && (
        <IconButton
          onClick={() => setLocation(`/babies/${babyId}/day/${day - 1}`)}
          sx={{ position: 'absolute', top: '21px', left: '10px' }}
        >
          <ArrowBackIosIcon />
        </IconButton>
      )}
      <IconButton
        onClick={() => setLocation(`/babies/${babyId}/day/${day + 1}`)}
        sx={{ position: 'absolute', top: '21px', right: '10px' }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
      <h1 style={{ marginBottom: '10px' }}>Day {day}</h1>
      <h2 style={{ fontSize: '14px', fontWeight: 400 }}>(Week {Math.ceil((day + 1) / 7)})</h2>
      <h2 style={{ fontSize: '14px', fontWeight: 400 }}>{dayStart} at {dayTime} - {dayEnd} at {dayTime}</h2>
      <Spacer>
        <ToggleButtonGroup exclusive value={view} onChange={(_, v) => setView(v)}>
          <ToggleButton value="events">
            <CalendarViewMonthIcon /> Events
          </ToggleButton>
          <ToggleButton value="stats">
            <BarChartIcon /> Stats
          </ToggleButton>
        </ToggleButtonGroup>
      </Spacer>
    </PageWidth>
  )
}
