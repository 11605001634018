import LoadingButton from '@mui/lab/LoadingButton'
import Paper from '@mui/material/Paper'
import { TimePicker } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'
import React from 'react'
import Flex from './Flex'

interface TimePickerCardProps {
  buttonLabel: string;
  label: string;
  loading: boolean;
  onClick?: () => void;
  onChange?: (val: Dayjs | null) => void;
  value: Dayjs | null;
}

function TimePickerCard({ buttonLabel, label, loading, onChange, onClick, value }: TimePickerCardProps, ref: React.Ref<HTMLDivElement>) {
  return (
    <Paper elevation={3} sx={{ padding: '20px' }} ref={ref}>
      <Flex direction="column" justify="space-around">
        <p>{label}</p>
        <TimePicker onChange={onChange} value={value} />
        <p>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={onClick}
            disabled={!value}
          >
            {buttonLabel}
          </LoadingButton>
        </p>
      </Flex>
    </Paper>
  )
}

export default React.forwardRef(TimePickerCard)
