import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { DatePicker } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'
import React from 'react'
import Flex from './Flex'

interface DatePickerCardProps {
  buttonLabel: string;
  label: string;
  onClick?: () => void;
  onChange?: (val: Dayjs | null) => void;
  value: Dayjs | null;
}

function DatePickerCard({ buttonLabel, label, onChange, onClick, value }: DatePickerCardProps, ref: React.Ref<HTMLDivElement>) {
  return (
    <Paper elevation={3} sx={{ padding: '20px' }} ref={ref}>
      <Flex direction="column" justify="space-around">
        <p>{label}</p>
        <DatePicker onChange={onChange} value={value} />
        <p>
          <Button variant="contained" onClick={onClick} disabled={!value}>{buttonLabel}</Button>
        </p>
      </Flex>
    </Paper>
  )
}

export default React.forwardRef(DatePickerCard)
