import React from 'react'

interface FlexProps {
  children: React.ReactNode
  align?: React.CSSProperties['alignItems'];
  direction?: React.CSSProperties['flexDirection'];
  fill?: boolean;
  grow?: React.CSSProperties['flexGrow'];
  height?: React.CSSProperties['height'];
  justify?: React.CSSProperties['justifyContent'];
  shrink?: React.CSSProperties['flexShrink'];
  scrollable?: boolean;
  style?: React.CSSProperties;
}

function Flex({
  align = 'center',
  children,
  direction = 'row',
  fill,
  grow,
  height,
  justify = 'center',
  scrollable = false,
  shrink,
  style = {},
}: FlexProps, ref: React.Ref<HTMLDivElement>) {
  const scrollableStyles = scrollable ? {
    overflowY: 'scroll',
    width: '100%',
  } : {}
  return (
    <div
      className={fill ? 'fill' : undefined}
      ref={ref}
      style={{
        alignItems: align,
        display: 'flex',
        flexDirection: direction,
        flexGrow: grow,
        flexShrink: shrink,
        height,
        justifyContent: justify,
        ...scrollableStyles as any,
        ...style
      }}
    >
      {children}
    </div>
  )
}

export default React.forwardRef(Flex)
