import AnimateIn from '~/components/AnimateIn'
import Button from '@mui/material/Button'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation'
import AddIcon from '@mui/icons-material/Add'
import React from 'react'
import NewChangingForm from './NewChangingForm'
import NewFeedingForm from './NewFeedingForm'
import Flex from '~/components/Flex'

export default function TrackNewEventButton() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const dropdownIsOpen = Boolean(anchorEl)
  const openDropdown = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const closeDropdown = () => setAnchorEl(null)

  const [eventType, setEventType] = React.useState<string>()
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const openModal = (et: 'feeding' | 'changing') => {
    setEventType(et)
    closeDropdown()
    setModalIsOpen(true)
  }
  const closeModal = () => setModalIsOpen(false)

  return (
    <React.Fragment>
      <AnimateIn>
        <Button variant="contained" startIcon={<AddIcon />} onClick={openDropdown}>
          Track new event
        </Button>
      </AnimateIn>
      <Menu
        id="add-new-event-dropdown"
        anchorEl={anchorEl}
        open={dropdownIsOpen}
        onClose={closeDropdown}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <MenuList>
          <MenuItem onClick={() => openModal('feeding')}>
            <ListItemIcon>🍼</ListItemIcon>
            <ListItemText>Feeding</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => openModal('changing')}>
            <ListItemIcon>
              <BabyChangingStationIcon />
            </ListItemIcon>
            <ListItemText>Changing</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
      <Modal disableEnforceFocus open={modalIsOpen} onClose={closeModal}>
        <Flex fill>
          {eventType === 'feeding' ? (
            <NewFeedingForm onCancel={closeModal} />
          ) : eventType === 'changing' ? (
            <NewChangingForm onCancel={closeModal} />
          ) : <></>}
        </Flex>
      </Modal>
    </React.Fragment>
  )
}
