import React from 'react'
import Pad from '~/components/Pad'
import Spacer from '~/components/Spacer'
import * as State from '~/state'
import * as DateUtils from '~/utils/date'
import EventChip from './EventChip'
import TrackNewEventButton from './TrackNewEventButton'

export default function Events() {
  const events = State.useState(State.select.events)
  const baby = State.useState(State.select.baby)
  const day = State.useState(State.select.day)

  if (!baby) {
    return
  }

  const eventsArr: (State.FeedingEvent | State.ChangingEvent)[] = Object.values(events)
  const sortedEvents = eventsArr.filter((ev) => ev.day === day)
  
  // sort by start time
  sortedEvents.sort((a, b) => DateUtils.getMsFromDateString(a.startTime) - DateUtils.getMsFromDateString(b.startTime))

  // offset by birth time
  const birthTime = DateUtils.getMsFromDateString(baby.birthTime)
  const index = sortedEvents.findIndex((ev) => DateUtils.getMsFromDateString(ev.startTime) >= birthTime)
  const newStart = sortedEvents.slice(index)
  const newEnd = sortedEvents.slice(0, index)
  const offsetEvents = [...newStart, ...newEnd]

  return (
    <div className="text-center">
      <Pad>
        <Spacer margin={2}>
          {!offsetEvents.length ? (
            <>
              <p>No events yet.</p>
              <p>Add one by clicking the button below.</p>
            </>
          ) : offsetEvents.map((e, i) => (
            <Spacer key={`${i}-${e.type}`}>
              <EventChip {...e} />
            </Spacer>  
          ))}
        </Spacer>
        <Spacer margin={2}>
          <TrackNewEventButton />
        </Spacer>
      </Pad>
    </div>
  )
}
