import BorderColorIcon from '@mui/icons-material/BorderColor'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import { TimePicker } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'
import React from 'react'
import Flex from '~/components/Flex'
import Pad from '~/components/Pad'
import Spacer from '~/components/Spacer'
import * as State from '~/state'

interface NewFeedingFormProps {
  onCancel: () => void;
}

export default function NewFeedingForm({ onCancel }: NewFeedingFormProps) {
  const createEvent = State.useState(State.select.createEvent)

  const [startTime, setStartTime] = React.useState<Dayjs | null>(null)
  const [endTime, setEndTime] = React.useState<Dayjs | null>(null)
  const [note, setNote] = React.useState<string | undefined>()
  const [noteIsShowing, setNoteIsShowing] = React.useState(false)

  const toggleNote = (e: React.MouseEvent) => {
    e.preventDefault()
    setNoteIsShowing(!noteIsShowing)
  }
  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value)
  }
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    createEvent({
      endTime: endTime ? endTime.toISOString() : null,
      note,
      startTime: (startTime as Dayjs).toISOString(),
      type: 'feeding',
    })
    onCancel()
  }

  return (
    <Paper sx={{ padding: '1em' }}>
      <form className="text-center" onSubmit={handleSubmit}>
        <h3>🍼 Track New Feeding</h3>
        <Spacer>
          <Pad>
            <Spacer>
              <p>Start time</p>
            </Spacer>
            <Spacer>
              <TimePicker onChange={setStartTime} value={startTime} />
            </Spacer>
          </Pad>
          <Pad>
            <Spacer>
              <p>End time</p>
            </Spacer>
            <Spacer>
              <TimePicker onChange={setEndTime} value={endTime} />
            </Spacer>
          </Pad>
          <Pad>
            <Spacer>
              <p onClick={toggleNote} style={{ cursor: 'pointer', color: '#69c' }}>
                <small>
                  <IconButton size="small" color="inherit">
                    <BorderColorIcon />
                  </IconButton>
                  {noteIsShowing ? 'Hide' : 'Show'} notes
                </small>
              </p>
              {noteIsShowing && (
                <TextField
                  label="Enter Notes Here"
                  multiline
                  maxRows={4}
                  onChange={handleNoteChange}
                  value={note}
                  sx={{ width: '100%' }}
                />
              )}
            </Spacer>
          </Pad>
        </Spacer>
        <Flex>
          <Pad>
            <Spacer>
              <Button variant="outlined" onClick={onCancel}>Cancel</Button>
            </Spacer>
          </Pad>
          <Pad>
            <Spacer>
              <Button disabled={!startTime} type="submit" variant="contained">Submit</Button>
            </Spacer>
          </Pad>
        </Flex>
      </form>
    </Paper>
  )
}
