import React from 'react'
import Flex from '~/components/Flex'
import LoadingScreen from '~/components/LoadingScreen'
import PageWidth from '~/components/PageWidth'
import Snackbar from '~/components/Snackbar'
import * as State from '~/state'
import Header from './Header'
import Events from './Events'
import Stats from './Stats'

export default function BabyPage({ babyId, day }: { babyId: string, day: string }) {
  const setBaby = State.useState(State.select.setBaby)
  const baby = State.useState(State.select.baby)
  const setDay = State.useState(State.select.setDay)
  const view = State.useState(State.select.view)

  const dayN = parseInt(day, 10)

  React.useEffect(() => {
    setBaby(babyId)
  }, [babyId, setBaby])

  React.useEffect(() => {
    setDay(dayN)
  }, [dayN, setDay])

  if (!baby) {
    return <LoadingScreen />
  }

  return (
    <Flex align="flex-start" direction="column" height="100%">
      <Header
        babyId={babyId}
        birthDate={baby.birthDate}
        birthTime={baby.birthTime}
        day={dayN}
        style={{ flexGrow: 0 }}
      />
      <Flex align="flex-start" scrollable grow={1}>
        <PageWidth>
          {view === 'events' ? (
            <Events />
          ) : view === 'stats' ? (
            <Stats />
          ) : null}
        </PageWidth>
      </Flex>
      <Snackbar />
    </Flex>
  )
}
