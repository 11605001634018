import React from 'react'

interface SpacerProps {
  children: React.ReactNode;
  margin?: number;
  style?: React.CSSProperties;
}

function Spacer({
  children,
  margin = 1,
  style = {},
}: SpacerProps, ref: React.Ref<HTMLDivElement>) {
  return (
    <div
      ref={ref}
      style={{
        marginBottom: `${margin}em`,
        marginTop: `${margin}em`,
        ...style
      }}
    >
      {children}
    </div>
  )
}

export default React.forwardRef(Spacer)
